.contact-page {
  display: flex;
  width: 100%;
  padding-top: 200px;
}

.contact-page-left {
  display: block;
  margin-left: 20%;
  width: 30%;
}

.contact-page-right {
  display: block;
  margin-left: 0%;
  width: 30%;
}

.contact-page-row {
  padding-top: 50px;
  display: flex;
}

.contact-page-icon {
  width: 8%;
}

.contact-page-row-left {
  width: 25%;
}

.contact-page-row-right {
  width: 70%;
}

#page-contact-message {
  height: 200px;
}

#page-content-btn {
  width: 200px;
  justify-content: center;
}

@media (max-width: 750px) {
  .contact-page {
    display: block;
    padding-top: 100px;
  }

  .contact-page-left {
    display: block;
    margin-left: 10%;
    width: 90%;
  }

  .contact-page-right {
    margin-top: 50px;
    display: block;
    margin-left: 10%;
    width: 80%;
    padding-bottom: 50px;
  }
}
