.pricebook {
  display: block;
  position: relative;
  padding-top: 200px;
  margin-left: 10%;
}

.pricebook h1 {
  text-align: center;
}

.pricebook-page {
  display: flex;
  width: 100%;
  padding-top: 120px;
}

.Description {
  display: flex;
  width: 100%;
  padding-top: 200px;
}

/* Create three columns of equal width */
.columns {
  float: left;
  width: 29.5%;
  padding: 8px;
}

/* Style the list */
.price {
  list-style-type: none;
  border: 1px solid #eee;
  margin: 0;
  padding: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

/* Add shadows on hover */
.price:hover {
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2);
}

/* Pricing header */
.price .header1 {
  background-color: rgb(172, 167, 167);
  color: white;
  font-size: 25px;
}

.price .header2 {
  background-color: rgb(136, 132, 132);
  color: white;
  font-size: 25px;
}

.price .header3 {
  background-color: rgb(87, 85, 85);
  color: white;
  font-size: 25px;
}

/* List items */
.price li {
  border-bottom: 1px solid #eee;
  padding: 20px 20px 20px 1%;
  text-align: center;
}

/* Grey list item */
.price .grey {
  background-color: #eee;
  font-size: 20px;
}

/* The "Sign Up" button */
.button {
  background-color: #04aa6d;
  border: none;
  color: white;
  padding: 10px 25px;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
}

/* Change the width of the three columns to 100%
  (to stack horizontally on small screens) */
@media only screen and (max-width: 650px) {
  .pricebook-page {
    display: block;
  }

  .pricebook {
    margin-left: 5%;
  }

  .columns {
    width: 95%;
  }
}
