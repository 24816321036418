.OrbituariesPerCity {
  padding: 200px 10% 0 10%;
}
.pagination {
  display: flex;
  list-style: none;
  justify-content: center;
  border: none;
  text-align: center;
  cursor: pointer;
}

.pagination li {
  border: none;
}

li.active {
  font-weight: bold;
}
.pagination a {
  padding: 10px;
  border: none;
}

@media (max-width: 1500px) {
  .OrbituariesPerCity {
    padding: 200px 7% 0 7%;
  }
}

@media (max-width: 1400px) {
  .OrbituariesPerCity {
    padding: 200px 5% 0 5%;
  }
}

@media (max-width: 1300px) {
  .OrbituariesPerCity {
    padding: 200px 3% 0 3%;
  }
}

@media (max-width: 750px) {
  .OrbituariesPerCity {
    padding: 150px 2% 50px 2%;
  }
}
