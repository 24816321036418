.buttonsInAdminPanel {
    justify-content: center;

    margin-top: 5%;
    display: flex;
}

#titleAdmin {
    text-align: center;
    margin-top: 2%;
}

#btnCommemoration {
    margin-left: 2%;
}

#btnLogInFinalGreeting {
    margin-left: 2%;
}

#logout {
    position: absolute;
    right: 0;
}

.save a{
    color: black;
}

.save a:visited {
    color: black;
    text-decoration: none;

  }
  
  /* mouse over link */
.save a:hover {
    color: black;
    text-decoration: none;

  }
  
  /* selected link */
.save a:active {
    color: black;
    text-decoration: none;
}