.MentionM {
    display: inline-block;
    width: 48%;
    margin: 1vh 2% 0 0;
    text-align: center;
    cursor: pointer;
}

.MentionMBox {
    width: 100%;
    border: 3px solid rgba(0, 0, 0, 0.8);
    height: 47vh;
}

.MentionMBox img {
    width: 12%;
    margin-top: 0;
    border: 1px solid black;
}

.MentionMBox p {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.8);
}

.MentionMBox .messageTop {
    margin: 1vh 0 2vh 8%;
    text-align: left;
}

.MentionMBox .messageDown {
    margin: 2vh 4% 2vh 4%;
    text-align: left;
}

.MentionMBox .mourners {
    margin: 0 5% 1vh 0;
    text-align: right;
    font-weight: bold;
}

.MentionMContent #dateOfPublishing{
    margin-top: 0;
    font-size: 11px;
    color: rgb(199, 199, 199);
}

@media (max-width: 750px){
    .MentionM {
        display: block;
        width: 98%;
        margin-left: 1%;
    }
    .MentionMBox .mourners {
        display: none;
    }
    .MentionMBox .messageDown {
        display: none;
    }
    .MentionMBox {
        height: 35vh;
    } 

    .MentionMBox img {
        width: 60px;
        border: 1px solid black;
        margin-bottom: 0px;
    }

    .MentionMBox h4 {
        font-size: 18px;
        margin-bottom: 0px;
    }

    .messageTop_LastGoodbye {
        margin-top: 2vh;
    }

    .MentionMBox p {
        font-size: 12px;
        color: gray;
        top: -100px;
    }
}


@media (max-width: 600px) {
    .MentionMBox {
        height: 30vh;
        width: 100%;
    } 
}

@media (max-height: 600px){
    .MentionMBox {
        height: auto;
        padding-bottom: 10px;
    } 
}

@media (max-width: 300px){
    .MentionMBox {
        height: auto;
    } 
}