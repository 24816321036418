.MentionL {
    display: inline-block;
    width: 48%;
    margin: 1vh 2% 0 0;
    text-align: center;
    text-decoration: none;
    color: black;
    cursor: pointer;
}

.MentionLBox {
    width: 100%;
    border: 3px solid rgba(0, 0, 0, 0.8);
    height: 88vh;
    padding: 5vh 6% 0 6%;
} 

.MentionLBox img {
    width: 20%;
    margin: 4vh 0 3vh 0;
    border: 1px solid black;
}

.MentionLBox p {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.8);
}

.MentionLContent #dateOfPublishing{
    margin-top: 0;
    font-size: 11px;
    color: rgb(199, 199, 199);
}

.MentionLBox .messageTop {
    margin: 3vh 0 3vh 8%;
    text-align: left;
}

.MentionLBox .messageDown {
    margin: 3vh 4% 3vh 4%;
    text-align: left;
}

.MentionLBox .mourners {
    margin: 0 5% 1vh 0;
    text-align: right;
    font-weight: bold;
}

.MentionLBox h3 {
    margin-bottom: 0;
}

.MentionLBox .dateOfBirthAndDeath {
    color: gray;
}

@media (max-width: 750px){
    .MentionL {
        display: block;
        width: 98%;
        margin-left: 1%;
    }

    .MentionLBox {
        height: 59vh;
    } 

    .MentionLBox img {
        width: 18%;
        margin-top: -3vh;
        border: 1px solid black;
    }

    .MentionLBox p {
        font-size: 14px;
        color: gray;
        margin-bottom: 3vh;
    }

    .MentionLBox h3 {
        font-size: 18px;
        margin: 0px 0 0px 0;
    }

    .MentionLBox #mourners {
        padding-top: 1.5vh;
        padding-bottom: 20px;
        font-size: 16px;
    }
}

@media (max-width: 600px) {
    .MentionLBox {
        height: 52vh;
        width: 100%;
        padding-top: 1vh;
    } 
    .MentionLBox .messageTop {
        margin-top: 1vh;
        margin-left: 4%;
        padding-bottom: 5px;

    }
    .MentionLBox #mourners{
        font-size: 14px;
    }

    .MentionLBox .messageDown {
        margin-top: 1vh;
        margin-bottom: 1vh;
    }

    .MentionLBox h3 {
        font-size: 18px;
    }
}

@media (max-height: 600px){
    .MentionLBox {
        height: auto;
    } 
}

@media (max-width: 300px){
    .MentionLBox {
        height: auto;
    } 
}