.about-us {
  padding-top: 50px;
  margin-left: 0%;
  padding-bottom: 100px;
}

.about-us-text {
  margin-left: 20%;
  width: 60%;
  font-size: 18px;
  text-align: justify;
}
