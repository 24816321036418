.footer_top {
  border-bottom: 1px solid gainsboro;
  padding: 50px 3%;
}

.footer_info {
  display: flex;
}

.footer_top .first {
  width: 25%;
  margin-right: 5%;
}

.footer_top .second {
  width: 20%;
  margin-right: 0;
}

.footer_top .third {
  width: 25%;
}

.footer_top .fourth {
  width: 25%;
}

.footer_top .fourth img {
  width: 80px;
  height: 50px;
  padding-right: 2%;
}

.footer_top ul li a {
  text-decoration: none;
  font-size: 14px;
  color: grey;
}

.footer_top ul li a:hover {
  color: coral;
}

.footer_top ul li {
  list-style: none;
  padding-bottom: 10px;
}

.footer_top ul {
  padding-left: 0;
}

.footer_top p {
  font-size: 14px;
  color: grey;
}

.footer_down {
  height: 107px;
  display: flex;
  color: grey;
  font-size: 14px;
}

.footer_down .footer_down_copyright {
  margin-left: 3%;
  margin-top: 25px;
}

.footer_down .footer_down_img {
  position: absolute;
  margin-left: 45%;
}

.footer_down img {
  width: 200px;
}

.footer_down .footer_down_links {
  position: absolute;
  right: 3%;
  margin-top: 25px;
  width: 270px;
}

.footer_down .footer_down_links .footer_down_link_2 {
  padding-left: 30px;
}

.footer_down a {
  text-decoration: none;
  color: grey;
}

.footer_down a:hover {
  color: coral;
}

@media (max-width: 750px) {
  .footer_top .footer_info {
    display: block;
    margin-top: -70px;
  }

  .footer_info .first {
    width: 94%;
    margin-left: 3%;
    text-align: center;
  }

  .footer_info .second {
    width: 94%;
    margin-left: 3%;
    text-align: center;
  }

  .footer_info .third {
    width: 94%;
    margin-left: 3%;
    text-align: center;
  }

  .footer_info .fourth {
    width: 94%;
    margin-left: 3%;
    text-align: center;
  }

  .footer_down {
    display: block;
    text-align: left;
  }

  .footer_down img {
    margin-left: -35%;
  }

  .footer_down .footer_down_links {
    width: 100%;
    text-align: right;
    margin: -33px 3% 0 0;
  }

  .footer_down .footer_down_links a {
    display: block;
  }
}
