.MentionXL {
    display: inline-block;
    margin: 1vh 0% 0 0%;
    text-align: center;
    text-decoration: none;
    color: black;
    width: 100%;
    cursor: pointer;
}

.MentionXLBox {
    width: 100%;
    border: 3px solid black;
    height: 88vh;
    padding: 2vh 6% 0 6%;
} 

.MentionXLBox img {
    width: 11%;
    margin: 2vh 0 3vh 0;
    border: 1px solid black;
}

.MentionXLBox p {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.8);
}

.MentionXLContent #dateOfPublishing{
    margin-top: 0;
    font-size: 11px;
    color: rgb(199, 199, 199);
}

.MentionXLBox .messageTop {
    margin: 3vh 0 3vh 3%;
    text-align: left;
}

.MentionXLBox .messageDown {
    margin: 3vh 8% 3vh 8%;
    text-align: left;
}

.MentionXLBox .mourners {
    margin: 0 2% 1vh 0;
    text-align: right;
    font-weight: bold;
}

.MentionXLBox h3 {
    margin-bottom: 0;
}

.MentionXLBox .dateOfBirthAndDeath {
    color: gray;
}

@media (max-width: 750px){
    .MentionXL {
        display: block;
        width: 98%;
        margin-left: 1%;
    }

    .MentionXLBox {
        height: 74vh;
        width: 100%;
        padding-top: 5vh;
    } 

    .MentionXLBox img {
        width: 18%;
        margin: 0;
        border: 1px solid black;
    }

    .MentionXLBox p{
        font-size: 14px;
    } 

    .MentionXLBox h3 {
        font-size: 18px;
        margin: 0px 0 0px 0;
    }

    .MentionXLBox .mourners {
        padding-top: 2vh;
        padding-bottom: 20px;
    }

    .MentionXLBox .mourners p {
        margin: 22px 0 0 -130px;
        font-size: 16px;
    }
}

@media (max-width: 600px) {
    .MentionXLBox .mourners p {
        font-size: 14px;
    }
}

@media (max-height: 600px){
    .MentionXLBox {
        height: auto;
    } 
}

@media (max-width: 320px){
    .MentionXLBox {
        height: auto;
    } 
}