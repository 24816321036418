.ObituaryXL {
  display: inline-block;
  width: 100%;
  margin: 1vh 2% 0 0;
  text-align: center;
  text-decoration: none;
  color: black;
}

.ObituaryXLBox {
  width: 97.5%;
  border: 4px double black;
  height: 88vh;
  padding: 5vh 6% 0 6%;
}

#ObituaryXLBox {
  height: 90vh;
  padding: 2vh 6% 0 6%;
}

.ObituaryXLBox img {
  width: 11%;
  margin-top: 4vh;
  border: 1px solid black;
}

.ObituaryXLBox .mourners {
  margin: 3vh 2% 0 2%;
  text-align: left;
  color: rgb(97, 97, 97);
  font-family: "Times New Roman", Times, serif;
  border-top: 1px solid rgb(189, 189, 189);
  padding-top: 3vh;
  text-align: justify;
}

.ObituaryXLBox .mourners #titleMourners {
  font-weight: bolder;
  letter-spacing: 2px;
  font-size: 20px;
}

.ObituaryXLBox .mourners #textMourners {
  font-size: 16px;
}

.ObituaryXLBox .mourners #titleMourners,
#textMourners {
  display: inline;
}

.ObituaryXLBox p {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
}

.ObituaryXLContent #dateOfPublishing {
  margin-top: 0;
  font-size: 11px;
  color: rgb(199, 199, 199);
}

hr {
  border: 0.1px solid grey;
}

.share {
  padding-top: 20px;
}

.share #wa {
  padding-left: 10px;
}

.img-symbol img {
  width: 60px;
  border: none;
}

@media (max-width: 750px) {
  .ObituaryXL {
    display: block;
    width: 98%;
    margin-left: 1%;
  }
  .share {
    padding-top: 10px;
  }

  .ObituaryXLBox {
    height: 80vh;
    width: 100%;
    padding-top: 3vh;
  }

  #ObituaryXLBox {
    height: 83vh;
    width: 100%;
    padding-top: 2vh;
  }

  .ObituaryXLBox img {
    width: 18%;
    margin: 0;
    border: 1px solid black;
  }

  .img-symbol img {
    width: 30px;
    border: none;
    padding-bottom: 30px;
  }

  .ObituaryXLBox p {
    font-size: 12px;
  }

  .ObituaryXLBox h3 {
    font-size: 18px;
    margin: 0px 0 0px 0;
  }

  .ObituaryXLBox .mourners {
    padding-top: 1vh;
    padding-bottom: 10px;
    text-align: left;
  }

  .ObituaryXLBox .mourners #textMourners {
    font-size: 14px;
  }

  .ObituaryXLBox .mourners #titleMourners {
    font-size: 18px;
  }
}

@media (max-width: 600px) {
  .ObituaryXLBox .mourners #textMourners {
    font-size: 14px;
  }

  .ObituaryXLBox .mourners #titleMourners {
    font-size: 18px;
  }

  .ObituaryXLBox {
    height: auto;
  }

  #ObituaryXLBox {
    height: auto;
  }
  .share {
    padding-bottom: 10px;
  }
}

@media (max-height: 600px) {
  .ObituaryXLBox {
    height: auto;
  }
  #ObituaryXLBox {
    height: auto;
  }
}

@media (max-width: 320px) {
  .ObituaryXLBox {
    height: auto;
  }
  #ObituaryXLBox {
    height: auto;
  }
}
