.ObituaryL {
  display: inline-block;
  width: 48%;
  margin: 1vh 2% 0 0;
  text-align: center;
  text-decoration: none;
  color: black;
  cursor: pointer;
}

.ObituaryLBox {
  width: 99%;
  border: 4px double black;
  height: 98vh;
  padding: 10vh 6% 0 6%;
}

.ObituaryLBox img {
  width: 20%;
  margin-top: 4vh;
  border: 1px solid black;
}

.ObituaryLBox #mourners {
  margin-left: 2%;
  text-align: left;
  color: rgb(97, 97, 97);
  letter-spacing: 3px;
  font-weight: bolder;
  font-family: "Times New Roman", Times, serif;
  border-top: 1px solid rgb(189, 189, 189);
  padding-top: 3vh;
}

.ObituaryLBox p {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.8);
}

.ObituaryLContent #dateOfPublishing {
  margin-top: 0;
  font-size: 11px;
  color: rgb(199, 199, 199);
}

@media (max-width: 750px) {
  .ObituaryL {
    display: block;
    width: 98%;
    margin-left: 1%;
  }

  .ObituaryLBox {
    height: 66vh;
    width: 100%;
    padding-top: 7vh;
  }

  .ObituaryLBox img {
    width: 18%;
    margin-top: -3vh;
    border: 1px solid black;
  }

  .ObituaryLBox p {
    font-size: 14px;
    color: gray;
    margin-bottom: 3vh;
  }

  .ObituaryLBox h3 {
    font-size: 18px;
    margin: 0px 0 0px 0;
  }

  .ObituaryLBox #mourners {
    padding-top: 1.5vh;
    padding-bottom: 20px;
    font-size: 16px;
  }
}

@media (max-width: 600px) {
  .ObituaryLBox {
    height: auto;
    width: 100%;
    padding-top: 5vh;
  }

  .ObituaryLBox .mourners #textMourners {
    font-size: 14px;
  }

  .ObituaryLBox .mourners #titleMourners {
    font-size: 18px;
  }
}

@media (max-height: 600px) {
  .ObituaryLBox {
    height: auto;
  }
}

@media (max-width: 300px) {
  .ObituaryLBox {
    height: auto;
  }
}
