.obituary-list {
    display: flex;
    flex-direction: column;
}

.obituary-row {
    display: flex;
    flex-direction: row;
}

.obituary-edit-button{
    border: none;
    background: none;
}
.obituary-name {
    width: 40%;
}
.obituary-date {
    width: 30%;
}
.obituary-update {
    width: 15%;
}
.obituary-delete {
    width: 15%
}