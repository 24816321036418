.ObituaryM {
  display: inline-block;
  width: 48%;
  margin: 1vh 2% 0 0;
  text-align: center;
  cursor: pointer;
}

.ObituaryMBox {
  width: 99%;
  border: 4px double black;
  height: 43.5vh;
}

.ObituaryMBox img {
  width: 15%;
  margin-top: 4vh;
  border: 1px solid black;
}

.ObituaryMBox p {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.8);
}

.ObituaryMContent #dateOfPublishing {
  margin-top: 0;
  font-size: 11px;
  color: rgb(199, 199, 199);
}

@media (max-width: 750px) {
  .ObituaryM {
    display: block;
    width: 98%;
    margin-left: 1%;
  }

  .ObituaryMBox {
    height: 35vh;
    width: 100%;
  }

  .ObituaryMBox img {
    width: 13%;
    border: 1px solid black;
    margin-bottom: 0px;
  }

  .ObituaryMBox h3 {
    margin-bottom: 0px;
    font-size: 18px;
  }

  .ObituaryMBox p {
    font-size: 12px;
    color: gray;
    top: -100px;
  }
}

@media (max-width: 600px) {
  .ObituaryMBox {
    height: 30vh;
    width: 100%;
  }
}

@media (max-height: 600px) {
  .ObituaryMBox {
    height: auto;
  }
}

@media (max-width: 300px) {
  .ObituaryMBox {
    height: auto;
  }
}
