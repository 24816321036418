.header {
  position: fixed;
  width: 100%;
  z-index: 10;
  background-color: white;
}

.header .first_box,
.second_box {
  border-bottom: 1px solid gainsboro;
}

.header .static_bar {
  height: 36px;
  width: 94%;
  margin: 0 0 0 3%;
}

.header .nav_bar {
  display: flex;
  height: 107px;
  width: 94%;
  margin: 0 0 0 3%;
}

.header ul {
  display: flex;
  list-style: none;
  float: right;
}

.header ul li {
  padding-right: 15px;
}

.header ul li a {
  text-decoration: none;
  color: gray;
}

.header .nav_bar .logo_header button {
  background-color: white;
  border: none;
}

.header .nav_bar .logo_header img {
  height: 120px;
  width: auto;
}

.header .nav_bar ul {
  right: 3%;
  position: absolute;
  margin-top: 42px;
}

.header .nav_bar ul a {
  background-color: transparent;
  border: 2px solid white;
  color: black;
  padding: 10px;
  font-weight: bold;
  font-size: 14px;
}

.header .nav_bar ul a:hover {
  border: 2px solid black;
}

.header .nav_bar button {
  background-color: transparent;
  border: none;
  outline: none;
}

.header .nav_bar button img {
  width: 70%;
}

.active {
  border: 2px solid black;
}

.header .static_bar ul {
  margin-top: 10px;
  font-size: 12px;
}

.header .static_bar ul li a:hover {
  color: coral;
}

.header .name_input {
  display: none;
  position: absolute;
  margin: 0;
  width: 100%;
  height: 107px;
  border-bottom: 1px solid gainsboro;
  margin-top: -108px;
  background-color: white;
}

.header .name_input input {
  width: 85%;
  padding: 0 10% 0 10%;
  height: 105px;
  border: 1px solid white;
  font-size: 20px;
}

.header .name_input input:focus {
  outline: none;
}

.header .name_input button {
  background-color: transparent;
  border: none;
  outline: none;
}

.header .name_input button img {
  margin-bottom: -10px;
  height: auto;
}

input {
  outline: none;
}

.header .name_input button:focus {
  border: none;
}

.header .name_input button:hover {
  border: none;
}

.header .name_input button:active {
  border: none;
}

.header .nav_bar_response {
  display: none;
}

.header .nav_bar_response ul {
  display: none;
}

.header .btn_response {
  display: none;
}

.header .input_response {
  display: none;
}

/* ---------- SCROLLED ---------- */

.header .first_box_scrolled {
  display: none;
}

.header .nav_bar_scrolled {
  display: flex;
  height: 67px;
  width: 94%;
  margin: 0 0 0 3%;
}

.header .nav_bar_scrolled .logo_header button {
  background-color: white;
  border: none;
}

.header .nav_bar_scrolled .logo_header img {
  height: 80px;
  width: auto;
}

.header .nav_bar_scrolled ul {
  right: 3%;
  position: absolute;
  margin-top: 22px;
}

.header .nav_bar_scrolled ul a {
  background-color: transparent;
  border: 2px solid white;
  color: black;
  padding: 10px;
  font-weight: bold;
  font-size: 14px;
}

.header .nav_bar_scrolled ul a:hover {
  border: 2px solid black;
}

.header .nav_bar_scrolled button {
  background-color: transparent;
  border: none;
}

.header .nav_bar_scrolled button img {
  width: 70%;
}

.header .nav_bar_scrolled button {
  outline: none;
}

.header .name_input_scrolled {
  display: none;
  position: absolute;
  margin: 0;
  width: 100%;
  height: 67px;
  border-bottom: 1px solid gainsboro;
  margin-top: -67px;
  background-color: white;
}

.header .name_input_scrolled input {
  width: 70%;
  padding: 0 10% 0 10%;
  height: 67px;
  margin-top: -1px;
  border: 1px solid white;
  border-bottom: 1px solid gainsboro;
  font-size: 20px;
}

.header .name_input_scrolled input:focus {
  outline: none;
}

.header .name_input_scrolled button {
  background-color: transparent;
  border: none;
}

.header .name_input_scrolled button img {
  margin-bottom: -8px;
  width: 100%;
  height: auto;
  outline: none;
}

.header .name_input_scrolled button {
  outline: none;
}

.header .logo_header_response {
  display: none;
}

/* ------------- CITY BUTTONS ------------- */

.city_buttons {
  display: none;
}

.city_buttons .cities {
  width: 100%;
  margin-left: 3%;
  padding: 50px 0 50px 0;
}

.city_buttons .cities .btn-cities {
  margin: 0 1% 0 1%;
  width: 90%;
  border: 2px solid black;
  padding: 5px;
  color: black;
  background-color: white;
}

.city_buttons .cities .other-cities {
  margin: 0 0 0 0;
  width: 100%;
  border: 2px solid black;
  padding: 5px;
  color: black;
  background-color: white;
}

.city_buttons .cities .btn-cities:hover {
  color: white;
  background-color: black;
}

.city_buttons .other-cities {
  margin: 0 0% 0 0%;
  width: 100%;
  text-align: center;
  padding: 5px;
  color: black;
  background-color: white;
}

.city_buttons .other-cities:hover {
  border: none;
  color: white;
  background-color: black;
}

.city_buttons .other-cities .btn-other-cities:hover {
  border: none;
  color: white;
  background-color: black;
}

.btn-other-cities {
  padding: 0 0 0 0;
  width: 90%;
}

.search-phone {
  display: none;
}

/* ------------- CITY BUTTONS ------------- */

@media (max-width: 820px) {
  .header .nav_bar .logo_header img {
    height: 80px;
  }
}

@media (max-width: 780px) {
  .header .nav_bar .logo_header img {
    height: 70px;
  }
}

@media (max-width: 750px) {
  .city_buttons .cities .btn-cities {
    width: 80%;
  }
  .btn-other-cities {
    margin-left: 1%;
    width: 80%;
  }

  .city_buttons .other-cities:hover {
    border: none;
    color: white;
    background-color: black;
  }

  .city_buttons .other-cities .btn-other-cities:hover {
    border: none;
    color: white;
    background-color: black;
  }
  .header .nav_bar ul {
    display: none;
  }

  .search-phone {
    display: flex;
  }

  .city_buttons {
    margin-top: 10px;
    margin-left: 10%;
    height: 100vh;
  }

  .city_buttons .cities {
    width: 100%;
    margin-left: 3%;
    padding: 0 0 0 0;
  }

  .header .second_box .logo_header {
    display: none;
  }

  .header .first_box {
    display: none;
  }

  .header .nav_bar_scrolled ul {
    display: none;
  }

  .header .name_input_scrolled {
    height: 107px;
    margin-top: -107px;
  }

  .header .name_input_scrolled input {
    border: none;
    margin-top: 20px;
  }

  .header .static_bar .static_bar_response {
    display: none;
  }

  .header .nav_bar_response {
    display: none;
  }

  .header .btn_response {
    display: block;
  }

  .header .nav_bar_response ul {
    display: block;
    background-color: white;
    width: 100%;
    height: 100vh;

    margin-top: -1px;
  }

  .header .nav_bar_response ul li {
    width: 94%;
    padding: 15px 0 0 3%;
    border-bottom: 1px solid gainsboro;
    color: black;
  }

  .header .nav_bar_response ul li a {
    color: black;
  }

  .header .second_box {
    height: 107px;
  }

  .header .logo_header_response {
    display: block;
    position: absolute;
    height: 107px;
    width: 100%;
    top: 0;
    text-align: center;
    background-color: transparent;
    z-index: -1000;
  }

  .header .logo_header_response img {
    height: 107px;
    width: auto;
  }

  .header .second_box .logo_header {
    margin: 1px 0 0 38%;
  }

  .header .input_response,
  .btn_response {
    display: block;
    position: absolute;
    margin-top: 70px;
    background-color: white;
    width: 22%;
  }

  .header .btn_response {
    left: 5%;
  }

  .header .input_response {
    right: 5%;
    margin-top: 40px;
    border: none;
  }

  .header .btn_response img {
    width: 50px;
    height: 50px;
    margin-top: -40px;
  }

  .header .btn_response #check {
    display: none;
  }

  .header .exit_input {
    position: absolute;
    height: 15%;
    right: 3%;
    top: 38px;
  }
}
