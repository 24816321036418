.container {
    margin-top: 5%;
}

.buttons {
    margin-top: 5%;
    margin-left: 40%;
    display: flex;
}
.login a{
    color: black;
}

.login a:visited {
    color: black;
    text-decoration: none;

  }
  
  /* mouse over link */
.login a:hover {
    color: black;
    text-decoration: none;

  }
  
  /* selected link */
.login a:active {
    color: black;
    text-decoration: none;
}

#logInDriver {
    display: none;
}

.goToLogin {
    margin-top: 5%;
    margin-bottom: 10%;
}

.goToLogin a{
    color: black;
}

#btnLogInDriver {
    margin-left: 2%;
}
