.ObituaryS {
    display: inline-block;
    width: 23%;
    margin: 1vh 2% 0 0;
    text-align: center;
    cursor: pointer;
}

.ObituarySBox {
    width: 100%;
    border: 4px double black;
    height: 43.5vh;
}

.ObituarySBox h3 {
    padding-top: 10vh;
}

.ObituarySBox p {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.8);
}


.ObituarySContent #dateOfPublishing{
    margin-top: 0;
    font-size: 11px;
    color: rgb(199, 199, 199);
}

@media (max-width: 750px){
    .ObituaryS {
        display: block;
        width: 98%;
        margin-left: 1%;
    }

    .ObituarySBox {
        width: 100%;
        border: 4px double black;
        height: 12vh;
        padding: 0;
        margin: 0;
    }

    .ObituarySBox h3 {
        padding-top: 0;
        font-size: 18px;
        margin: 10px 0 0px 0;
    }

    .ObituarySBox p {
        font-size: 12px;
    }
}

@media (max-width: 600px) {
    .ObituarySBox {
        height: 12vh;
        width: 100%;
    } 
}

@media (max-height: 600px){
    .ObituarySBox {
        height: auto;
    } 
}

@media (max-width: 300px){
    .ObituarySBox {
        height: auto;
    } 
}