html {
    scroll-behavior: smooth;
}

#submit {
    display: none;
}

#button {
    margin-bottom: 50px;
}

#submit {
    margin-bottom: 50px;
}