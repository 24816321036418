.formular .back {
    margin-left: 14%;
    padding-top: 50px;
    color: black;
}

.formular a:visited {
    color: black;
    text-decoration: none;

  }
  
  /* mouse over link */
.formular a:hover {
    color: black;
    text-decoration: none;

  }
  
  /* selected link */
.formular a:active {
    color: black;
    text-decoration: none;

}