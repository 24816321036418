.obituary-dialog {
    padding: 0 0 0 0;
}

.dialog-button {
    background: none;
    border: none;
}

.obituary-dialog-xl {
    display: inline-block;
    width: 100%;
    margin: 0 0 0 0;
    text-align: center;
    text-decoration: none;
    color: black;
}

.obituary-dialog-content #date-of-publishing {
    margin-top: 0;
    font-size: 11px;
    color: rgb(199, 199, 199);
}

.obituary-dialog-box {
    width: 100%;
    border: 4px double black;
    height: auto;
    padding: 0vh 6% 0 6%;
}

.obituary-dialog-box #mourners {
    margin-left: 2%;
    text-align: left;
    color: rgb(97, 97, 97);
    letter-spacing: 3px;
    font-weight: bolder;
    font-family: 'Times New Roman', Times, serif;
    border-top: 1px solid rgb(189, 189, 189);
    padding-top: 3vh;
}

.obituary-dialog-box img {
    width: 11%;
    margin-top: 4vh;
    border: 1px solid black;
}

.obituary-dialog-box p {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.8);
}

.obituary-dialog-box .mourners {
    margin: 3vh 2% 0 2%;
    text-align: left;
    color: rgb(97, 97, 97);
    font-family: 'Times New Roman', Times, serif;
    border-top: 1px solid rgb(189, 189, 189);
    padding-top: 3vh;
    padding-bottom: 5vh;

    text-align: justify;
}

.obituary-dialog-box .mourners #title-mourners{
    font-weight: bolder;
    letter-spacing: 2px;
    font-size: 25px;
}

.obituary-dialog-box .mourners #text-mourners{
    font-size: 18px;
}

.obituary-dialog-box .mourners #title-mourners, #text-mourners {
    display: inline;
}

.obituary-dialog-box p {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.8);
}

@media (max-width: 600px){
    .obituary-dialog-box .mourners {
        text-align: left;
        padding-bottom: 25px;
    }

    .obituary-dialog-box .mourners #text-mourners{
        font-size: 14px;
    }

    .obituary-dialog-box .mourners #title-mourners{
        font-size: 18px;
    }

    .obituary-dialog-box {
        height: auto;
    }
    .obituary-dialog-box img {
        width: 60px;
    }

    .obituary-dialog-box h3 {
        font-size: 14px;
    }
}

@media (max-height: 600px) {
    .obituary-dialog-box {
        height: auto;
    }
    .obituary-dialog-box .mourners {
        text-align: left;
        padding-bottom: 25px;
    }
}
